export const RoutingOptimizationIcon = ({color}: {color: string}) => (
  <svg
    aria-hidden="true"
    className="svg-inline--fa fa-route fa-w-16"
    data-icon="route"
    data-prefix="fad"
    focusable="false"
    height="100%"
    role="img"
    viewBox="0 0 512 512"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g className={`text-${color}-500 fa-group`}>
      <path
        className="fa-secondary"
        d="M512 416a96.15 96.15 0 0 1-96 96H138.2a690.4 690.4 0 0 0 47.3-64H416a32 32 0 0 0 0-64h-96a96 96 0 0 1 0-192h45.24A799.82 799.82 0 0 0 416 256h-96a32 32 0 0 0 0 64h96a96.15 96.15 0 0 1 96 96z"
        fill="currentColor"
        key="secondary"
        opacity="0.4"
      />
      <path
        className="fa-primary"
        d="M96 256a96 96 0 0 0-96 96c0 53 96 160 96 160s96-107 96-160a96 96 0 0 0-96-96zm0 128a32 32 0 1 1 32-32 32 32 0 0 1-32 32zM416 0a96 96 0 0 0-96 96c0 53 96 160 96 160s96-107 96-160a96 96 0 0 0-96-96zm0 128a32 32 0 1 1 32-32 32 32 0 0 1-32 32z"
        fill="currentColor"
        key="primary"
      />
    </g>
  </svg>
);
