export const AnalyticsIcon = ({color}: {color: string}) => (
  <svg
    aria-hidden="true"
    className="svg-inline--fa fa-chart-area fa-w-16"
    data-icon="chart-area"
    data-prefix="fad"
    focusable="false"
    height="100%"
    role="img"
    viewBox="0 0 512 512"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g className={`text-${color}-500 fa-group`}>
      <path
        className="fa-secondary"
        d="M500 384a12 12 0 0 1 12 12v40a12 12 0 0 1-12 12H12a12 12 0 0 1-12-12V76a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12v308z"
        fill="currentColor"
        key="secondary"
        opacity="0.4"
      />
      <path
        className="fa-primary"
        d="M390.1 164.2L480 352H96V248l86.8-144.7a12 12 0 0 1 19.9-1L288 216l84.7-56.5a12 12 0 0 1 17.4 4.7z"
        fill="currentColor"
        key="primary"
      />
    </g>
  </svg>
);
