export const DriverAppIcon = ({color}: {color: string}) => (
  <svg
    aria-hidden="true"
    className="svg-inline--fa fa-truck fa-w-20"
    data-icon="truck"
    data-prefix="fad"
    focusable="false"
    height="100%"
    role="img"
    viewBox="0 0 640 512"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g className={`text-${color}-500 fa-group`}>
      <path
        className="fa-secondary"
        d="M176 352a80 80 0 1 0 80 80 80 80 0 0 0-80-80zm288 0a80 80 0 1 0 80 80 80 80 0 0 0-80-80z"
        fill="currentColor"
        key="secondary"
        opacity="0.4"
      />
      <path
        className="fa-primary"
        d="M624 352h-16V243.9a48 48 0 0 0-14.1-33.9L494 110.1A48 48 0 0 0 460.1 96H416V48a48 48 0 0 0-48-48H48A48 48 0 0 0 0 48v320a48 48 0 0 0 48 48h18.16C74 361.93 119.78 320 176 320s102.54 41.86 110.38 96h67.24c7.85-54.14 54.1-96 110.38-96s102 41.93 109.84 96H624a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm-64-96H416V144h44.1l99.9 99.9z"
        fill="currentColor"
        key="primary"
      />
    </g>
  </svg>
);
//   <svg
//     aria-hidden="true"
//     focusable="false"
//     data-prefix="fad"
//     data-icon="mobile"
//     className="svg-inline--fa fa-mobile fa-w-10"
//     role="img"
//     xmlns="http://www.w3.org/2000/svg"
//     viewBox="-160 0 640 512"
//   >
//     <g className={`text-${color}-500 fa-group`}>
//       <path
//         className="fa-secondary"
//         fill="currentColor"
//         d="M0 384v80a48 48 0 0 0 48 48h224a48 48 0 0 0 48-48v-80zm160 96a32 32 0 1 1 32-32 32 32 0 0 1-32 32z"
//         opacity="0.4"
//       ></path>
//       <path
//         className="fa-primary"
//         fill="currentColor"
//         d="M0 384V48A48 48 0 0 1 48 0h224a48 48 0 0 1 48 48v336z"
//       ></path>
//     </g>
//   </svg>
// );
